/* motoblog */
.Main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.App-container {
  text-align: left;
  /* background-color: #282c34; */
  color: #282c34;
  padding: 20px;
}

.App-container h1 {
  margin: 30px 0px 20px;
}

.App-container h3 {
  margin: 30px 0px 50px;
}

.twitterlogo {
  width: 70px;
  height: 70px;
}

.twitterSvg {
  width: 50px;
  height: 50px;
}

.App-container p {
  font-size: 17px;
  margin-bottom: 20px;
}
.portlink {
  color: white;
  margin-top: 50px;
}

/* content */
.dateOfEntry {
  display: flex;
  justify-content: flex-end;
}

.contents-container p {
  line-height: 1.8em;
  margin: 2em 0;
}
.content-box {
  margin-bottom: 120px;
}

/* portfolio */

.port-container img {
  width: 300px;
  margin-right: 30px;
}

.contents-Box {
  display: flex;
  margin: 30px 0px;
}
.contents-Box h4 {
  margin: 0px;
  font-size: 25px;
}

.port-container h2,
.prof-container h2 {
  font-size: 40px;
}

.port-container h3 {
  margin-bottom: 0px;
}

.port-container h4,
.prof-container h4,
p {
  margin: 0px;
}

/* === profile === */
.prof-container {
  margin-bottom: 50px;
}

.name-container {
  display: flex;
  border-bottom: 3px solid gray;
}

.namebox h2 {
  font-size: 30px;
  margin: 0px 0px 0px;
  padding: 0px;
  line-height: 37px;
}

.mailbarthbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
}

.prof-container h3 {
  margin: 50px 0px 0px;
  border-bottom: 3px solid gray;
}

.profile-twitterlogo {
  width: 30px;
  height: 30px;
  background-color: black;
  border-radius: 10%;
}

.proficon-box {
  display: flex;
  align-items: center;
  margin-left: 50px;
}
.iconarea {
  margin: 10px;
}

.proficonbtn {
  width: 40px;
  height: 40px;
}
.skillbox .skillitems {
  font-weight: bold;
  margin-bottom: 15px;
}

.categoryitem {
  width: 100px;
  border-bottom: 3px solid gray;
  margin: 30px 0px 10px;
  font-weight: bold;
}

.indent {
  padding-left: 2rem;
}

.referencebook {
  height: 200px;
  display: flex;
  width: 300px;
  margin-right: 30px;
}

.referencebook1 {
  height: 200px;
  object-fit: contain;
  width: 300px;
  margin-right: 30px;
}
.referencebook2 {
  height: 170px;
  object-fit: contain;
  margin-top: 12px;
}

.closingsentence {
  margin: 20px 0px 50px;
}

/* ============================= */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (min-width: 600px) {
  /* 600px以上用のスタイル */
  .App-container,
  .pp-container {
    width: 700px;
  }
}

/* コード装飾 */
pre,
.codedisplay {
  margin: 0 0 25px 0;
  padding: 0.5em;
  border-radius: 5px;
  background: #25292f;
  color: #fff;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  width: 80%;
}

.codetitle {
  display: inline-block;
  background: #25292f;
  color: #fff;
  border-radius: 5px 5px 0 0;
  padding: 0.2em;
  margin-bottom: -10px;
}

.Precodewidth {
  width: 600px;
}
